<template>
  <v-container>
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        sm="4"
        md="4"
      >
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <router-link :to="{ name: 'Admin' }">
          <v-btn
            class=" ma-2 mt-6"
            color="success"
            dark
          >
            <v-icon
              left
            >
              mdi-home-outline
            </v-icon>Admin Home
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Download Selection
          </v-card-title>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
              v-model="selected"
              :items="downloadSelection"
              return-object
              dense
              outlined
              label="Select Download Type"
              color="primary"
              @change="getDownloadList(selected.value)"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="selected !== ''">
      <v-col cols="4">
        <v-card class="ml-2">
          <v-data-table
            v-model="selected"
            item-key="fileType"
            class="mt-10"
            :headers="downloadOrderHeaders"
            :items="orders"
            :search="search"
            footer-props="true"
            single-select
            show-select
            checkbox-color="blue"
          >
            <template>
              <div>
                <v-chip
                  class="ma-2"
                  color="success"
                  outlined
                >
                  <v-icon left>
                    mdi-file-download-outline
                  </v-icon>
                  File Name
                </v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { nowwService } from '@/shared/services'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'DownloadProccessedJobs',
  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      search: '',
      resultsLoading: false,
      downloadSelection: [
        { text: 'Quotes', value: 'quotes' },
        { text: 'Job Numbers', value: 'jobNumbers' }
      ],
      selected: '',
      results: [],
      downloadOrderHeaders: [
        {
          text: 'File',
          align: 'start',
          value: 'file'
        },
        { text: 'Download', value: 'download', sortable: true }
      ]
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      // if (listeners.includes(mutation.type)) {
      //   this.getOmitCodesStart()
      // }
    })
  },

  methods: {
    async getDownloadList (value) {
      var _this = this
      var customerNowwId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.resultsLoading = true
      await nowwService.getDownloadList({
        selectedType: value,
        customerId: customerNowwId
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
          }
        })
    }
  }
}
</script>
